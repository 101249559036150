import Image from "next/image";
import { useEffect } from "react";
import { Trans, t } from "@lingui/macro";
import { useRouter } from "next/router";
import classNames from "@/utils/classNames";

export default function ChatCTA() {
  const { locale } = useRouter();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const handleScroll = () => {
    const CTA = document.getElementById("wsp-cta");
    if (window.scrollY > 700) {
      CTA.classList.remove("-right-96");
      CTA.classList.add("right-4");
    } else {
      CTA.classList.add("-right-96");
      CTA.classList.remove("right-4");
    }
  };

  return (
    <div
      id="wsp-cta"
      className="fixed bottom-4 transition-all -right-96 md:bottom-8 md:right-8 z-30"
    >
      <a
        target="_blank"
        rel="noreferrer"
        href={t`https://api.whatsapp.com/send/?phone=56939170273&text&type=phone_number&app_absent=0`}
      >
        <div
          className={classNames(
            locale === "pt-BR" ? "w-[300px]" : "w-60",
            "absolute -top-12 right-0  px-4 py-2 rounded-l-md rounded-tr-md bg-white text-center shadow"
          )}
        >
          <Trans>Lets chat on WhastApp!</Trans>
        </div>
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href={t`https://api.whatsapp.com/send/?phone=56939170273&text&type=phone_number&app_absent=0`}
      >
        <Image
          width={44}
          height={44}
          src="/rrss/whatsapp.png"
          className="shadow"
          alt="Whatsapp Chat link"
        />
      </a>
    </div>
  );
}
